<template>
  <div>
    <global-header/>
    <slot/>
    <globalFooter/>
  </div>
</template>
<script>
export default {
}
</script>